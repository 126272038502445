@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GabrielSans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/Gabriel-Sans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Chivo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Chivo-Regular.woff2') format('woff2');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

.h2 {
  @apply text-orange-600 text-center font-display font-bold text-lg md:text-3xl leading-tight
}

.h3 {
  @apply text-neutral-900 font-display font-bold text-base md:text-xl leading-snug
}

.subtitle {
  @apply mt-1 md:mt-4 text-center text-neutral-800 text-sm md:text-base
}

.body {
  @apply  text-neutral-800 text-sm md:text-base
}

.full-container {
  @apply max-w-full mx-auto px-5 xl:px-0
}


.outer-container {
  @apply max-w-2xl lg:max-w-outer mx-auto px-5 xl:px-0
}

.inner-container {
  @apply max-w-xl lg:max-w-inner mx-auto px-5 lg:px-0
}